<template>
  <HomePage />
</template>

<script setup>
// @ is an alias to /src
import { useHead } from "@unhead/vue";
import HomePage from "@/components/HomePage.vue";
import meta from "@/util/meta";

useHead({
  title: meta.defaultTitle,
  meta: [
    {
      name: "description",
      content: meta.defaultDescription,
    },
  ],
});
</script>

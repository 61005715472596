import constant from "@/util/constant";

var sprintf  = require('sprintf-js').sprintf,
    vsprintf = require('sprintf-js').vsprintf

const websiteName = '豆瓣直租'
const rentalKeywords = [ '直租', '租房', '找房', '房东', '房源' ]

export default {
	canonicalDomainName: 'https://dbzz.house',
	websiteName: websiteName,
	defaultTitle: websiteName + ' - 无中介租房',
	defaultDescription: sprintf('一二线城市（%s），豆瓣小组房东房源，直租找房。', constant.topCities.join('/'))
}
<template>
  <div class="page-container">
    <NavigationBar @city="searchPostByCity" ref="navBar" />

    <div id="main">
      <div id="search-box">
        <form @submit.prevent="searchPost">
          <input type="text" placeholder="请输入地铁站 / 商圈 / 小区 / 地铁线路…" v-model="query.keyword" id="search-input" />
          <button>搜索</button>
        </form>
      </div>

      <div id="options-filter-box">
        <div class="options-filter-item level-one-height">
          <div class="filter-head">
            <span class="filter-title" title="区域">区域</span>：
          </div>
          <div class="filter-body">
            <div class="filter-body-items">
              <ul>
                <li v-for="item in districts[query.city]" :key="item.id">
                  <a href="#" @click="searchPostByDistrict(item.district)" :class="[{ nav_filter_clicked: item.clicked }]" >{{ item.description }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="options-filter-item level-two-height">
          <div class="filter-head">
            <span class="filter-title" title="地铁">地铁（可多选）</span>：
          </div>
          <div class="filter-body">
            <div class="filter-body-items">
              <ul>
                <li v-for="item in subways[query.city]" :key="item.id">
                  <a href="#" @click="searchPostBySubway(item.subway)" :class="[{ nav_filter_clicked: item.clicked }]" >{{ item.description }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="options-filter-item level-one-height">
          <div class="filter-head">
            <span class="filter-title" title="房源类型">房源类型</span>：
          </div>
          <div class="filter-body">
            <div class="filter-body-items">
              <ul>
                <li v-for="item in publisherRoles" :key="item.id">
                  <a href="#" @click="searchPostByPublisherRole(item.role)" :class="[{ nav_filter_clicked: item.clicked }]" >{{ item.description }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="options-filter-item level-one-height">
          <div class="filter-head">
            <span class="filter-title" title="是否去重">是否去重</span>：
          </div>
          <div class="filter-body">
            <div class="filter-body-items">
              <ul>
                <li v-for="item in uniqueFlags" :key="item.id">
                  <a href="#" @click="searchPostByUniqueFlag(item.flag)" :class="[{ nav_filter_clicked: item.clicked }]" >{{ item.description }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="options-filter-item level-one-height">
          <div class="filter-head">
            <span class="filter-title" title="最近几天内发布">最近几天内发布</span>：
          </div>
          <div class="filter-body">
            <div class="filter-body-items">
              <ul>
                <li v-for="item in recentDaysCandidates" :key="item.id">
                  <a href="#" @click="searchPostByRecentDays(item.days)" :class="[{ nav_filter_clicked: item.clicked }]" >{{ item.days }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div id="post-table">
        <p>
          <vxe-tooltip ref="xTip"></vxe-tooltip>
          <vxe-grid v-bind="gridOptions"></vxe-grid>
          <vxe-pager
            perfect
            @page-change="searchPost"
            v-model:current-page="query.currentPage"
            v-model:page-size="query.pageSize"
            :total="query.totalElements"
            :page-sizes="constant.availablePageSizes"
            :layouts="[
              'PrevJump',
              'PrevPage',
              'JumpNumber',
              'NextPage',
              'NextJump',
              'Sizes',
              'FullJump',
              'Total',
            ]"
          >
            <template #right>
              <img id="duck-img" alt="duck" :src="duck" />
              <img id="duck-img" alt="duck" :src="duck" />
            </template>
          </vxe-pager>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import router from "@/router";
import NavigationBar from "@/components/NavigationBar.vue";
import api from "@/api/backend-api";
import util from "@/util/util";
import doubanUtil from "@/util/douban-util";
import constant from "@/util/constant";
import duck from "../../public/img/duck-136x160.gif";

const navBar = ref(null);

onMounted(() => {
  searchPostByUrl();
});

// for Vue v-for loop
let idTmp = 0;
const recentDaysCandidates = ref([]);
for (const days of constant.recentDays) {
  recentDaysCandidates.value.push({
    id: idTmp++,
    days: days,
    clicked: days == constant.defaultRecentDays,
  });
}

var districts = ref({});
for (const [city, value] of Object.entries(constant.districtCounter)) {
  idTmp = 0;
  var l = [
    {
      id: idTmp++,
      district: constant.emptyStringValue,
      clicked: true,
      description: constant.emptyDesc,
    },
  ];
  for (const district of Object.keys(value)) {
    l.push({
      id: idTmp++,
      district: district,
      clicked: false,
      description: district,
    });
  }
  districts.value[city] = l;
}

var subways = ref({});
for (const [city, value] of Object.entries(constant.subwayCounter)) {
  idTmp = 0;
  l = [
    {
      id: idTmp++,
      subway: constant.emptyStringValue,
      clicked: true,
      description: constant.emptyDesc,
    },
  ];
  for (const subway of Object.keys(value)) {
    l.push({
      id: idTmp++,
      subway: subway,
      clicked: false,
      description: subway,
    });
  }
  subways.value[city] = l;
}

idTmp = 0;
const publisherRoles = ref([
  {
    id: idTmp++,
    role: constant.emptyStringValue,
    clicked: false,
    description: constant.emptyDesc,
  },
  {
    id: idTmp++,
    role: constant.defaultPublisherRole,
    clicked: true,
    description: "直租",
  },
]);

idTmp = 0;
const uniqueFlags = ref([
  {
    id: idTmp++,
    flag: false,
    clicked: false,
    description: constant.emptyDesc,
  },
  { id: idTmp++, flag: constant.defaultUniqueFlag, clicked: true, description: "去重" },
]);

const gridOptions = ref({
  border: true,
  round: true,
  autoResize: true,
  headerAlign: "center",
  align: "center",
  scrollY: { enabled: false },
  columns: [
    {
      field: "postTitle",
      title: "标题",
      headerAlign: "center",
      align: "left",
      type: "html",
      minWidth: "38%",
      sortable: true,
    },
    { field: "OP", title: "楼主", type: "html", minWidth: "167", sortable: true },
    {
      field: "opLatestCommentTime",
      title: "楼主发帖/回复时间",
      minWidth: "167",
      sortable: true,
    },
    {
      field: "group",
      title: "小组",
      type: "html",
      minWidth: "167",
      sortable: true,
    },
  ],
  data: [],
});

const defaultQueryValue = {
  city: constant.defaultCity,
  district: constant.emptyStringValue,
  subway: constant.emptyStringValue,
  keyword: constant.emptyStringValue,
  recentDays: constant.defaultRecentDays,
  publisherRole: constant.defaultPublisherRole,
  uniqueFlag: constant.defaultUniqueFlag,
  pageSize: constant.defaultPageSize,
  currentPage: constant.defaultCurrentPage,
  totalElements: 300,
};

const query = ref(structuredClone(defaultQueryValue));

function getQueryParams() {
  const queryValue = query.value;
  var queryParams = { city: defaultQueryValue.city };
  if (!util.isEmpty(queryValue.city) && queryValue.city != defaultQueryValue.city) {
    queryParams["city"] = queryValue.city;
  }
  if (!util.isEmpty(queryValue.district)) {
    queryParams["district"] = queryValue.district;
  }
  if (!util.isEmpty(queryValue.subway)) {
    queryParams["subway"] = queryValue.subway;
  }
  if (!util.isEmpty(queryValue.keyword)) {
    queryParams["keyword"] = queryValue.keyword;
  }
  if (queryValue.recentDays != defaultQueryValue.recentDays) {
    queryParams["recentDays"] = queryValue.recentDays;
  }
  if (queryValue.publisherRole != defaultQueryValue.publisherRole) {
    queryParams["publisherRole"] = queryValue.publisherRole;
  }
  if (queryValue.uniqueFlag != defaultQueryValue.uniqueFlag) {
    queryParams["uniqueFlag"] = queryValue.uniqueFlag;
  }
  if (queryValue.pageSize != defaultQueryValue.pageSize) {
    queryParams["pageSize"] = queryValue.pageSize;
  }
  if (queryValue.currentPage != defaultQueryValue.currentPage) {
    queryParams["currentPage"] = queryValue.currentPage;
  }
  return queryParams;
}

function generatePostArray(response) {
  const postArray = [];
  response.body.content.forEach((item) => {
    const post = {
      postTitle: util.getAnchorTagStr(
        doubanUtil.getUrlFromId(item.postId, "topic"),
        item.postTitle,
        item.postId
      ),
      OP: util.getAnchorTagStr(
        doubanUtil.getUrlFromId(item.opId, "people"),
        item.nickName,
        item.opId
      ),
      opLatestCommentTime: item.opLatestCommentTime,
      group: util.getAnchorTagStr(
        doubanUtil.getUrlFromId(item.groupId, "group"),
        item.groupName,
        item.groupId
      ),
    };
    postArray.push(post);
  });
  return postArray;
}

function searchPost() {
  const queryParams = getQueryParams();
  // console.log(queryParams);
  const queryValue = query.value;
  router.push({ path: "/search", query: queryParams });
  api
    .searchPost(
      queryParams.city,
      queryValue.district,
      queryValue.subway,
      queryValue.keyword,
      queryValue.recentDays,
      queryValue.publisherRole,
      queryValue.uniqueFlag,
      queryValue.pageSize,
      queryValue.currentPage
    )
    .then((response) => {
      gridOptions.value.data = generatePostArray(response.data);
      query.value.totalElements = response.data.body.totalElements;
    })
    .catch((error) => {
      console.error(error);
    });
}

function setDistrictClickStatus(city, district) {
  districts.value[city].forEach((item) => {
    item.clicked = item.district == district;
  });
}

// l[0] 对应的值必须为 constant.emptyStringValue
function setEmptyItemClickStatus(l, flag) {
  if (l[0].clicked != flag) {
    l[0].clicked = flag;
  }
}

// 支持多选 + 反选。
function setSubwayClickStatus(city, subway) {
  // 记录被选中的 subway item（emptyStringValue 除外）
  var l = [];
  for (const item of subways.value[city]) {
    if (subway == constant.emptyStringValue) {
      item.clicked = item.subway == subway;
    } else if (item.subway == subway) {
      item.clicked = !item.clicked;
      if (item.clicked) {
        setEmptyItemClickStatus(subways.value[city], false);
      }
    }
    if (item.subway != constant.emptyStringValue && item.clicked) {
      l.push(item.subway);
    }
  }
  if (l.length <= 0) {
    setEmptyItemClickStatus(subways.value[city], true);
  }
  return l;
}

function setSubwayClickStatusViaUrl(city, subway) {
  const subwaySet = new Set(subway.split(","));
  for (const item of subways.value[city]) {
    for (const subway of subwaySet) {
      item.clicked = item.subway == subway;
      if (item.clicked) {
        break;
      }
    }
  }
}

function setPublisherRoleClickStatus(publisherRole) {
  for (const item of publisherRoles.value) {
    item.clicked = item.role == publisherRole;
  }
}

function setUniqueFlagClickStatus(uniqueFlag) {
  for (const item of uniqueFlags.value) {
    item.clicked = item.flag == uniqueFlag;
  }
}

function setRecentDaysClickStatus(recentDays) {
  for (const item of recentDaysCandidates.value) {
    item.clicked = item.days == recentDays;
  }
}

function searchPostByUrl() {
  util.mergeRecursive(
    constant.queryParamTypeMap,
    query.value,
    "skip_extra",
    router.currentRoute.value.query
  );
  navBar.value.setCityClickStatus(query.value.city);
  setDistrictClickStatus(query.value.city, query.value.district);
  setSubwayClickStatusViaUrl(query.value.city, query.value.subway);
  setPublisherRoleClickStatus(query.value.publisherRole);
  setUniqueFlagClickStatus(query.value.uniqueFlag);
  setRecentDaysClickStatus(query.value.recentDays);
  searchPost();
}

function resetQuery() {
  query.value = structuredClone(defaultQueryValue);
  setPublisherRoleClickStatus(query.value.publisherRole);
  setUniqueFlagClickStatus(query.value.uniqueFlag);
  setRecentDaysClickStatus(query.value.recentDays);
}

// 当切换城市时，重置 query（及相应的 click status）
function searchPostByCity(city) {
  resetQuery();
  navBar.value.setCityClickStatus(city);
  query.value.city = city;
  searchPost();
}

function searchPostByDistrict(district) {
  setDistrictClickStatus(query.value.city, district);
  query.value.district = district;
  searchPost();
}

function searchPostBySubway(subway) {
  const checkedSubways = setSubwayClickStatus(query.value.city, subway);
  query.value.subway = checkedSubways.toString();
  searchPost();
}

function searchPostByPublisherRole(publisherRole) {
  setPublisherRoleClickStatus(publisherRole);
  query.value.publisherRole = publisherRole;
  searchPost();
}

function searchPostByUniqueFlag(uniqueFlag) {
  setUniqueFlagClickStatus(uniqueFlag);
  query.value.uniqueFlag = uniqueFlag;
  searchPost();
}

function searchPostByRecentDays(recentDays) {
  setRecentDaysClickStatus(recentDays);
  query.value.recentDays = recentDays;
  searchPost();
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/global.scss";
@import "@/styles/main.scss";

#main {
  margin: 0px 4px 32px;
}

#search-box {
  margin: 0px 0px 32px;
}

#search-input {
  // width: 300px;
  min-width: 18rem;
  padding: 2.5px;
}

#options-filter-box {
  border: 1px solid #e8e8e8;
  padding: 4px 8px;
  // height: 13rem;
  display: flex;
  display: -webkit-flex; /* Safari */
  flex-direction: column;
  justify-content: space-between;
}

.options-filter-item {
  height: auto;
  display: flex;
  display: -webkit-flex; /* Safari */
  justify-content: flex-start;
  align-items: center;
}

.level-one-height {
  flex: 1 2 auto;
}

.level-two-height {
  flex: 2 1 auto;
}

.filter-head {
  display: flex;
  display: -webkit-flex; /* Safari */
}

.filter-title {
  display: inline-block;
  width: 7rem;
  text-align: justify;
  text-align-last: justify;
  // 兼容 IE
  text-justify: distribute-all-lines;
  // TODO: 不兼容 Safari
}

.filter-body-items ul {
  list-style-type: none;
  padding: 0;
  height: auto;
  display: flex;
  display: -webkit-flex; /* Safari */
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0.5rem 1rem;
}

.filter-body-items a {
  text-decoration: none;
}

#post-table {
  margin: 32px 0px;
}

#duck-img {
  height: 32px;
}
</style>

export default {
	// 根据 id + id_type 得到 URL
	getUrlFromId(id, idType) {
		var url = ''
		if (['people', 'group'].includes(idType))
			url = `https://www.douban.com/${idType}/${id}`
		else if (idType == 'topic')
			url = `https://www.douban.com/group/topic/${id}`
		else if (idType == 'joined_group')
			url = `https://www.douban.com/group/people/${id}/joins`
		return url
	}
}

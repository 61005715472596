import axios from 'axios'
import constant from "@/util/constant";

const axiosApi = axios.create({
    // baseURL: 'http://localhost:9527/api',
    baseURL: `/api`,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});

export default {
    searchPost(city = constant.defaultCity, district = constant.emptyStringValue, subway = constant.emptyStringValue, keyword = constant.emptyStringValue, recentDays = 0, publisherRole = constant.defaultPublisherRole, uniqueFlag = constant.defaultUniqueFlag, pageSize = 0, currentPage = 0, sortField = '', sortOrder = '') {
        const path = `/search?city=${city}&district=${district}&subway=${subway}&keyword=${keyword}&recent_days=${recentDays}&publisher_role=${publisherRole}&unique_flag=${uniqueFlag}&page_size=${pageSize}&current_page=${currentPage}&sort_field=${sortField}&sort_order=${sortOrder}`
        // console.log(path)
        return axiosApi.get(path)
    }
}

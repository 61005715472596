export default {
	isEmpty(str) {
		return !str || str.length === 0;
	},

	mergeRecursive(propTypeMap, obj, flag /*, …*/) {
		for (var i = 2; i < arguments.length; i++) {
			for (var prop in arguments[i]) {
				var val = arguments[i][prop];
				if (typeof val == "object")
					// this also applies to arrays or null!
					this.mergeRecursive(obj[prop], flag, val);
				else if (
					(flag == "skip_extra" && Object.hasOwn(obj, prop)) ||
					(flag == "skip_exist" && !Object.hasOwn(obj, prop)) ||
					flag == "merge_all"
				) {
					if (!Object.hasOwn(propTypeMap, prop)) obj[prop] = val;
					else if (propTypeMap[prop] == "number") obj[prop] = Number(val);
					else if (propTypeMap[prop] == "boolean") obj[prop] = val === "true";
				}
			}
		}
		return obj;
	},

	getAnchorTagStr(url, text, hint = "") {
		var hintAttribute = this.isEmpty(hint) ? "" : `title="${hint}"`;
		return `<a href="${url}" target="_blank" ${hintAttribute}>${text}</a>`;
	},

	isMobile(navigator) {
		let hasTouchScreen = false;
		if ("maxTouchPoints" in navigator) {
			hasTouchScreen = navigator.maxTouchPoints > 0;
		} else if ("msMaxTouchPoints" in navigator) {
			hasTouchScreen = navigator.msMaxTouchPoints > 0;
		} else {
			const mQ = matchMedia?.("(pointer:coarse)");
			if (mQ?.media === "(pointer:coarse)") {
				hasTouchScreen = !!mQ.matches;
			} else if ("orientation" in window) {
				hasTouchScreen = true; // deprecated, but good fallback
			} else {
				// Only as a last resort, fall back to user agent sniffing
				const UA = navigator.userAgent;
				hasTouchScreen =
					/\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
					/\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
			}
		}
		return hasTouchScreen;
	}
};

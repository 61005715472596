import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import '@/styles/vxe-table.scss'

import { createHead } from '@unhead/vue'

const head = createHead();

createApp(App).use(store).use(router).use(VXETable).use(head).mount('#app')

export default {
  emptyStringValue: "",
  emptyDesc: "不限",
  topCities: [
    "北京",
    "上海",
    "广州",
    "深圳",
    "杭州",
    "成都",
    "苏州",
    "南京",
    "重庆",
    "武汉",
    "天津",
    "长沙",
    "西安",
    "青岛",
    "郑州",
    "宁波",
    "佛山",
    "东莞",
    "合肥",
  ],
  defaultCity: "北京",
  recentDays: [1, 3, 7, 15, 30],
  defaultRecentDays: 7,
  defaultPublisherRole: "landlord",
  defaultUniqueFlag: true,
  defaultCurrentPage: 1,
  availablePageSizes: [20, 50, 100],
  defaultPageSize: 20,
  queryParamTypeMap: { 'recentDays': 'number', 'currentPage': 'number', 'pageSize': 'number', 'uniqueFlag': 'boolean' },
  districtCounter: await import("../../conf/district_counter.json", { assert: { type: "json" } }),
  subwayCounter: await import("../../conf/subway_counter.json", { assert: { type: "json" } })
}
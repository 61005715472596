<template>
  <router-view />
  <CopyrightICP></CopyrightICP>
</template>

<script setup>
import router from "@/router";
import { useHead } from "@unhead/vue";
import meta from "@/util/meta";
import CopyrightICP from "./components/CopyrightICP.vue";

useHead(() => ({
  link: [
    {
      rel: "canonical",
      href: meta.canonicalDomainName + router.currentRoute.value.fullPath,
      // href: window.location.origin + router.currentRoute.value.fullPath,
    },
  ],
}));
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 14px;

  display: flex;
  display: -webkit-flex; // Safari
  flex-direction: column;
  height: 100%;
}

.main {
  margin-top: 32px;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
  text-decoration: none;
}

a:link {
  color: #259;
  text-decoration: none;
}

a:visited {
  color: #769;
  text-decoration: none;
}

a:hover {
  color: #fff;
  text-decoration: none;
}

a:active {
  color: #fff;
  text-decoration: none;
}

#options-filter-box a:hover {
  background: #37a;
}

#post-table a:hover {
  background: #37a;
}
</style>
